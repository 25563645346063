
import React, {useEffect, useState} from 'react';


export default function Create(props) {
    let staticJSON = {
        "store-name": "New store",
        "store-logo": "",
        "first-name": "Testing",
        "last-name": "test",
        "email": "test@gmail.com",
        "phone-number": "test",
        "street-address": "test",
        "city": "test",
        "state": "test",
        "zip-code": "test",
        "country": "test",
        "store-description": "test",
        "terms-and-conditions": "test"
    }

    async function CreateStoreRequest(userEntry) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            mode: "cors",
            body: JSON.stringify(userEntry)
        };

        const response = await fetch('https://756lh958pd.execute-api.us-east-1.amazonaws.com/default/createStore', requestOptions);
        const data = await response.json();
        console.log(data);

        return data;
    }

    function handleRequest() {
        /*
        let form = document.getElementById("create-store");
        let formData = new FormData(form);
        let object = {};
        formData.forEach((value, key) => {
            object[key] = value;
        });
        let json = JSON.stringify(object);
        */
        CreateStoreRequest(staticJSON);
    }


    return (
        <>
            <h1>Create Store</h1>
            <form id="create-store">
                <div>
                    <label>Store Name</label>
                    <input 
                        type="text"
                        name="store-name"
                        required={false}
                    />
                </div>

                <div>
                    <button>Upload Logo</button>
                    <input 
                        type="file" 
                        name="store-logo"
                        accept="image/*"
                        required={false}
                    />
                </div>

                <div>
                    <label>First Name</label>
                    <input 
                        type="text"
                        name="first-name"
                        required={false}
                    />
                </div>

                <div>
                    <label>Last Name</label>
                    <input 
                        type="text" 
                        name="last-name"
                        required={false}
                    />
                </div>

                <div>
                    <label>Email</label>
                    <input 
                        type="text"
                        name="email"
                        required={false}
                    />
                </div>

                <div>
                    <label>Phone Number</label>
                    <input 
                        type="text" 
                        name="phone-number"
                        required={false}
                    />
                </div>

                <div>
                    <label>Street Address</label>
                    <input 
                        type="text"
                        name="street-address"
                        required={false}                             
                    />
                </div>

                <div>
                    <label>City</label>
                    <input 
                        type="text" 
                        name="city"
                        required={false}
                    />
                </div>

                <div>
                    <label>State</label>
                    <input 
                        type="text"
                        name="state"
                        required={false} 
                    />
                </div>

                <div>
                    <label>Zip Code</label>
                    <input 
                        type="text"
                        name="zip-code"
                        required={false}
                    />
                </div>

                <div>
                    <label>Country</label>
                    <input 
                        type="text"
                        name="country"
                        required={false} 
                    />
                </div>

                <div>
                    <label>Store Description</label>
                    <textarea 
                        name="store-description" 
                        form="create-store"
                        required={false}
                    ></textarea>
                </div>

                <div>
                    <a href="">Terms and Conditions</a>
                    <input 
                        type="checkbox"
                        name="terms-and-conditions"
                        required={false}
                    />
                </div>

                <div>
                    <button onClick={handleRequest}>Create Store</button>
                </div>
            </form>

        </>
    )
}
